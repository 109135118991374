import React, { useState, useCallback } from "react"
import { Flexbox, Box, Text, Heading } from "@ballchy/styled-bits"
import styled, { keyframes } from "styled-components"
import { Overlay } from "atoms"
import Footer from "components/Footer"

import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline"
import { ArrowIosBackOutline } from "@styled-icons/evaicons-outline/ArrowIosBackOutline"
import { useStaticQuery, graphql } from "gatsby"
import WebsiteCard from "components/WebsiteCard"

const ProjectPageContainer = () => {
  const [step, setStep] = useState(0)

  const data = useStaticQuery(graphql`
    query {
      pandaFinderImage: file(relativePath: { eq: "panda-finder.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      shopImage: file(relativePath: { eq: "stripe-shop.png" }) {
        childImageSharp {
          fluid(maxWidth: 1040) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const handleSlideRight = useCallback(() => {
    if (step < 1) {
      setStep(step + 1)
    }
  }, [setStep, step])
  const handleSlideLeft = useCallback(() => {
    if (step > 0) {
      setStep(step - 1)
    }
  }, [setStep, step])

  return (
    <>
      <Overlay
        id="project-page-overlay"
        fontSize={["270px", "270px", "520px"]}
        textAlign="center"
        textTransform="uppercase"
      >
        Chi
      </Overlay>
      <StreetCarAnimationContainer
        display={["none", "none", "inherit"]}
        overflow="hidden"
      >
        <StreetCarSVG
          width={["150px", "800px", "400px"]}
          as="img"
          display="block"
          src="/streetcar.svg"
          alt="streetcar"
        />
      </StreetCarAnimationContainer>
      <Flexbox
        as="section"
        id="project-page-container"
        position="relative"
        flexDirection="column"
        minHeight={["auto", "52em", "100vh"]}
        maxWidth="1040px"
        justifyContent="center"
        mx="auto"
        px={3}
        py={[3, 3, 4]}
      >
        <Box id="heading" mt={[5, 5, 2]} mb={[3, 3, 4]}>
          <Heading fontSize={[5, 5, 7]} textTransform="uppercase">
            Projects
          </Heading>
        </Box>
        <Slider overflow="hidden" flexDirection={["column", "column", "row"]}>
          <SlidingFlexbox
            id="shop-project-split-screen"
            flex={["0 1 auto", "0 1 auto", "1 0 100%"]}
            flexDirection={["column-reverse", "column-reverse", "row"]}
            position="relative"
            overflow="hidden"
            maxWidth="100%"
            step={step}
          >
            <Flexbox
              id="project-page-text-container"
              flexDirection="column"
              justifyContent={["flex-start", "flex-start", "center"]}
              flex={["1 0 100%", "1 0 100%", "1 0 50%"]}
              maxWidth={["100%", "100%", "40%"]}
              px={[3, 3, 0]}
              width={[1, 1, 1 / 2]}
            >
              <Heading mb={3} textTransform="uppercase" fontSize={[3, 3, 4]}>
                E-Commerence Site with Stripe and Contentful
              </Heading>
              <Text as="p" mb={2}>
                You can call this website the "Amazon knockoff" because it is a
                e-commerence that I built in my free time. The idea came alive
                when JAMStack site started to gain popularity a couple years
                ago. The website is built using React and Gatsbyjs. The UI
                design is still in development, but the site is backed by
                powerful web services like Stripe Checkout, Contentful CMS and
                Netlify that can supports a small to medium online business.
              </Text>
            </Flexbox>
            <Flexbox
              ic="project-page-image-caontainer"
              flexDirection="column"
              justifyContent="center"
              flex={["1 0 100%", "1 0 100%", "1 0 50%"]}
              maxWidth={["100%", "100%", "60%"]}
              p={[3, 3, 4]}
              my={[3, 0, 0]}
            >
              <WebsiteCard
                url="https://www.ballchy.dev"
                imgData={data.shopImage}
              ></WebsiteCard>
            </Flexbox>
          </SlidingFlexbox>
          <SlidingFlexbox
            id="project-page-split-screen"
            flex={["0 1 auto", "0 1 auto", "1 0 100%"]}
            flexDirection={["column-reverse", "column-reverse", "row"]}
            position="relative"
            overflow="hidden"
            maxWidth="100%"
            step={step}
          >
            <Flexbox
              id="project-page-text-container"
              flexDirection="column"
              justifyContent={["flex-start", "flex-start", "center"]}
              flex={["1 0 100%", "1 0 100%", "1 0 50%"]}
              maxWidth={["100%", "100%", "40%"]}
              px={[3, 3, 0]}
              width={[1, 1, 1 / 2]}
            >
              <Heading mb={3} textTransform="uppercase" fontSize={[3, 3, 4]}>
                Find Cute Pandas on Google Maps
              </Heading>
              <Text as="p" mb={2}>
                Panda Finder is a concept that I have come up when I was
                experimenting the Google Maps's Javascript API. It started off
                as my own customizable Google Maps with unique styles. The
                website uses a bunch of cool Google Maps services (Google Maps,
                Google Places, Google Geodecoding) that enables you to find
                random (imaginary) Pandas around the world. It is not as
                practical as I would like but I sure had a lot of fun building
                it. Just look at that Panda icon!
              </Text>
            </Flexbox>
            <Flexbox
              ic="project-page-image-caontainer"
              flexDirection="column"
              justifyContent="center"
              flex={["1 0 100%", "1 0 100%", "1 0 50%"]}
              maxWidth={["100%", "100%", "60%"]}
              p={[3, 3, 4]}
              my={[3, 0, 0]}
            >
              <WebsiteCard
                url="https://panda-finder.ballchy.dev"
                imgData={data.pandaFinderImage}
              ></WebsiteCard>
            </Flexbox>
          </SlidingFlexbox>
        </Slider>
        <LeftArrowContainer step={step} onClick={handleSlideLeft}>
          <LeftArrow />
        </LeftArrowContainer>
        <RightArrowContainer step={step} onClick={handleSlideRight}>
          <RightArrow />
        </RightArrowContainer>
        <Footer />
      </Flexbox>
    </>
  )
}

const move = keyframes`
    0%, 10%, 100% {
      
      transform: translateX(200%);
    }
    50%, 60% {
      transform: translateX(-100%);
    }
`

const StreetCarAnimationContainer = styled(Box)`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -200;
`

const StreetCarSVG = styled(Box)`
  animation-name: ${move};
  animation-duration: 40000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

const Slider = styled(Flexbox)`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 100%;
    top: 0;
    right: 0px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      var(--background) 100%
    );

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints[0]}) {
      background: none;
    }
  }
`

const RightArrowContainer = styled.a`
  cursor: ${props => (props.step === 1 ? "not-allowed" : "pointer")};
  position: absolute;
  top: 50%;
  right: -100px;
  z-index: 1000;
  transform: translate(0, -50%);
  border-radius: 50%;
  transition: background-color 0.5s ease-out;

  &:hover {
    background-color: rgba(200, 200, 200, 0.3);
  }
`

const RightArrow = styled(ArrowIosForwardOutline)`
  width: 100px;
  height: 100px;
`

const LeftArrowContainer = styled.a`
  cursor: ${props => (props.step === 0 ? "not-allowed" : "pointer")};
  position: absolute;
  top: 50%;
  left: -125px;
  z-index: 1000;
  transform: translate(0, -50%);

  border-radius: 50%;
  transition: background-color 0.5s ease-out;

  &:hover {
    background-color: rgba(200, 200, 200, 0.3);
  }
`

const LeftArrow = styled(ArrowIosBackOutline)`
  width: 100px;
  height: 100px;
`

const SlidingFlexbox = styled(Flexbox)`
  transform: ${props => `translate(${props.step * -100}%, 0)`};
  transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1) 0s;
`

export default ProjectPageContainer
