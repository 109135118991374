import React from "react"
import styled, { css, keyframes } from "styled-components"
import Img from "gatsby-image"
import { Flexbox, Box } from "@ballchy/styled-bits"
import { ArrowRight } from "@styled-icons/heroicons-solid/ArrowRight"
import { DotsVertical } from "@styled-icons/heroicons-solid/DotsVertical"
import { ArrowLeft } from "@styled-icons/heroicons-solid/ArrowLeft"

const WebsiteCard = ({ url, imgData }) => {
  return (
    <WindowLink href={url}>
      <WindowBar>
        <RedDot />
        <GrayDot />
        <GreenDot />
        <WebLeftArrow />
        <WebRightArrow />
        <Spacer />
        <AdressBar>
          <div>{url}</div>
        </AdressBar>
        <Spacer />
        <MoreButton />
      </WindowBar>
      <WebpageImg fluid={imgData.childImageSharp.fluid} />
    </WindowLink>
  )
}

const WebpageImg = styled(Img)`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

const ButtonCss = css`
  width: 20px;
  height: 20px;
  color: white;
  margin: 0 10px;
`

const HideMobile = css`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    display: none;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: none;
  }
`

const WindowBar = styled(Flexbox)`
  background: #101010;
  height: 50px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  & > * {
    align-self: center;
  }
`

const WindowLink = styled.a`
  text-decoration: none;
  color: #363636;
  width: 100%;
  overflow: hidden;

  border: 0.5px solid rgba(100, 100, 100, 0.7);
  border-radius: 10px;
  transition: all 0.2s ease-in;
  box-shadow: 1px 1px 7px -2px rgba(55, 55, 55, 0.75);

  &:hover {
    box-shadow: 10px 10px 15px -2px rgba(55, 55, 55, 0.75);
    transform: translate(0%, -3px);
  }
`

const RedDot = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: orangered;
  margin: 0 3px 0 15px;
  ${HideMobile}
`
const GrayDot = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: gray;
  margin: 0 3px;
  ${HideMobile}
`

const GreenDot = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: limegreen;
  margin: 0 15px 0 3px;
  ${HideMobile}
`

const MoreButton = styled(DotsVertical)`
  ${ButtonCss}
  ${HideMobile}
`

const WebRightArrow = styled(ArrowRight)`
  ${ButtonCss}
  ${HideMobile}
`

const WebLeftArrow = styled(ArrowLeft)`
  ${ButtonCss}
  ${HideMobile}
`

const Spacer = styled(Box)`
  flex: 1;
`
const typing = keyframes`
  from { width: 0 }
  to { width: 100% }

`

const blink = keyframes`
  from, to { border-color: transparent }
  50% { border-color: black; }
`

const AdressBar = styled(Flexbox)`
  height: 25px;
  background: rgb(255, 255, 255);
  color: black;
  border-radius: 15px;
  padding: 5px 20px;
  margin: 0 15px;
  font-size: 12px;
  text-align: center;
  & > div {
    height: 85%;
    padding-right: 1px;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0.2em solid black; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto;
    /* givesthat scrolling effect as the typing happens */
    letter-spacing: 0.1em; /* Adjust as needed */
    animation: ${typing} 2.5s steps(45, end), ${blink} 0.75s step-end infinite;
  }
`

export default WebsiteCard
