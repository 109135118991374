import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"
import ProjectPageContainer from "container/projects"

const ProjectPage = () => (
  <Layout>
    <SEO title="Full-stack Developer" />
    <ProjectPageContainer />
  </Layout>
)

export default ProjectPage
